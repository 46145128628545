import axios from 'axios';

const api = axios.create({
  baseURL: 'https://caab.homolog.komunick.com/api',
  // baseURL: 'https://qa.ykt.com.br/api-caap/api',
  // baseURL: 'http://localhost:4000/api',
  headers: {
    xal: ".ZmH[A6}X#9y6~Ex{{hgm'gBhhz##UShQ4%;=7QQAu",
    platform: "cms",
    appversion: "cms-0.0.5"
  }
});

api.interceptors.request.use(function (config) {
  let jwt = localStorage.getItem('token')
  if (jwt) {
    config.headers['Authorization'] = `Bearer ${jwt}`;
  }
  return config;
});

export default api;
