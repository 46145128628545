/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Collapse,
  IconButton,
  makeStyles,
  Box,
  Container,
  Card,
  CardContent,
  Grid,
  createMuiTheme,
  MuiThemeProvider,
  Avatar,
  Divider,
  MenuItem,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Autocomplete, Alert, TreeItem, TreeView } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import pt from 'date-fns/locale/pt-BR';
import {
  getAllServicos,
  getAllUnidadesToSchedule,
  rejectSchedule,
  finishSchedule,
  confirmSchedule,
  updateScheduleEdit,
  getAllIndisponible,
  getAllSchedules,
  getAllExtraHour,
  getHistoricFilterUser,
  findSchedulesBasedOnProperties
} from '../../../../providers/schedules';
import { add, differenceInDays, format } from 'date-fns';
import CloseIcon from '@material-ui/icons/Close';
import { capitalize, values } from 'lodash'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';
import ReactLoading from "react-loading";
import { alterationLog } from '../../../../providers/log';
import * as _ from "lodash";
import ReactTooltip from 'react-tooltip';
import Backdrop from "@material-ui/core/Backdrop";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white,
  },
  submitBtn: {
    background: color.red,
    color: color.white,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  Avatar: {
    width: 200,
    height: 200,
    margin: '8px auto',
    boxShadow: '0px 0px 0px 6px #FFFFFF, 0px 0px 0px 10px #B22222'
  },
  textArea: {
    minHeight: 80
  },
  option: {
    backgroundColor: 'white',
  },
  diolog: {
    background: color.white,
  },
  dialogRoot: {
    background: 'rgba(0.0.0, 0.3)',
  },
  cardHours: {
    display: "flex",
    width: "100%",
    textAlignLast: "center",
    marginBottom: 10,
    "&:hover": {
      cursor: "pointer",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});

function AlertBar(props) {
  return <Alert elevation={6} variant="filled" {...props} />;
}

export default function EditSchedule(props) {

  const status = [
    {
      value: 'confirmed',
      label: 'Confirmado'
    },
    {
      value: 'reject',
      label: 'Cancelado'
    },
    {
      value: 'await',
      label: 'Aguardando'
    },
    {
      value: 'not_appear',
      label: 'Não Compareceu'
    },
    {
      value: 'finished',
      label: 'Finalizado'
    }
  ]

  const classes = useStyles()
  const schedule = props.data
  const [error, setError] = useState(false)
  const [hourInit, onChangeHourInit] = useState(new Date().setHours(`${schedule.horaInicio.split(':')[0]}`, `${schedule.horaInicio.split(':')[1]}`));
  const [servicoValue, setServicoValue] = useState({});
  const [unidadeValue, setUnidadeValue] = useState({});
  const [profissionalValue, setProfissionalValue] = useState({});
  const [procedimentoValue, setProcedimentoValue] = useState({});
  const [profissionais, setProfissionais] = useState([])
  const [procedimentos, setProcedimentos] = useState([])
  const [procedures, setProcedures] = useState([])
  const [servicoForDependent, setServicoForDependent] = useState()
  const [procedimentoForDependent, setProcedimentoForDependent] = useState()
  const [locais, setLocais] = useState([])
  const [locaisFiltered, setLocaisFiltered] = useState([])
  const [servicos, setServicos] = useState([])
  const [procTime, setProcTime] = useState('');

  // const [date, setDate] = useState(new Date());
  // const [hour, setHour] = useState(new Date("2020-10-10 00:00"));
  const [date, setDate] = useState(new Date(schedule.data + 'T04:00:00'));
  const [hour, setHour] = useState(new Date().setHours(`${schedule.horaInicio.split(':')[0]}`, `${schedule.horaInicio.split(':')[1]}`));
  const [almocoInit, setAlmocoInit] = useState(new Date(new Date().setHours(schedule.profissional.horaAlmocoInicio.split(':')[0], schedule.profissional.horaAlmocoInicio.split(':')[1], 0)));
  const [almocoFim, setAlmocoFim] = useState(new Date(new Date().setHours(schedule.profissional.horaAlmocoFim.split(':')[0], schedule.profissional.horaAlmocoFim.split(':')[1], 0)));
  const [horarioOcupado, setHorarioOcupado] = useState(false)
  const [severity, setSeverity] = useState('error')
  const [openDialogReject, setOpenDialogReject] = useState(false);
  const [openDialogFinish, setOpenDialogFinish] = useState(false);
  const [textRejectReason, setTextRejectReason] = useState('')
  const [indisponible, setIndisponible] = useState([])
  const [blockAlert, setBlockAlert] = useState(false)
  const [professionalSchedules, setProfessionalSchedules] = useState()
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });
  const [pickedDate, setPickedDate] = useState();
  const [loadingProf, setLoadingProf] = useState(false);
  const [profList, setProfList] = useState([]);
  const [verifyProf, setVerifyProf] = useState(true);
  const [
    indisponibleOfCurrentProfessional,
    setIndisponibleOfCurrentProfessional,
  ] = useState([]);
  const [professionalSchedulesException, setProfessionalSchedulesException] =
    useState([]);

  const [professionalHours, setProfessionalHours] = useState([])
  const [invalidHour, setInvalidHour] = useState(false)
  const [open, setOpen] = useState(false);

  const [isExtraDay, setIsExtraDay] = useState(false);

  const user = useSelector(({ user }) => user);
  const [card, setCard] = useState('none');

  const [stateShow, setStateShow] = useState(true)

  const [exception, setException] = useState(false)

  const [extraHour, setExtraHour] = useState([]);
  const [lockExtraHourInit, setLockExtraHourInit] = useState(false)

  const userData = useSelector(({ user }) => user);
  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.schedule);
    };

    fetchPermissions();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const locais = await getAllUnidadesToSchedule();
        setLocais(locais);

        const servicos = await getAllServicos();
        setServicos(servicos);

        const indis = await getAllIndisponible();
        setIndisponible(indis);

        const extra = await getAllExtraHour();
        setExtraHour(extra);

        const idUser = schedule.id;
      } catch (error) {
        console.log(error);
      }
    }
    add(new Date(), { hours: 3 })
    fetchData();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(procedimentoValue)) {
      fetchSchedulesBasedOnProperties().then((scheduleTemp) => {
        setProfessionalHours(hoursToSchedule(date, scheduleTemp) || []);
      });
    }
  }, [procedimentoValue, date]);

  async function fetchSchedulesBasedOnProperties() {
    const sched = await findSchedulesBasedOnProperties({
      data: date,
      idProfissional: profissionalValue.id,
      idServico: servicoValue.id,
      idUnidade: unidadeValue.id,
    });

    const scheduleTemp = sched.filter((a) => a.exception === false);
    setProfessionalSchedules(scheduleTemp);
    setProfessionalSchedulesException(
      sched.filter((a) => a.exception === true)
    );

    return scheduleTemp;
  }

  const handleClose = (event, reason) => {
    setBlockAlert(false);
    setInvalidHour(false);
    if (reason === "clickaway") {
      return;
    }
    setHorarioOcupado(false);
  };

  const handleCloseBlockAlert = (event, reason) => {
    setBlockAlert(false);
    setInvalidHour(false);
  };

  function disableWeekDays(day) {
    const week = [
      !profissionalValue.domingo,
      !profissionalValue.segunda,
      !profissionalValue.terca,
      !profissionalValue.quarta,
      !profissionalValue.quinta,
      !profissionalValue.sexta,
      !profissionalValue.sabado,
    ];

    const extraFiltered = extraHour.filter((a) =>
      a.professionals.some((b) => b.id === profissionalValue.id)
    );
    const verifyExtra = extraFiltered.some(
      (a) => new Date(a.dataInit).valueOf() === new Date(day).valueOf()
    );
    if (!!verifyExtra) return false;

    return week[day.getDay()];
  }

  function pickHour(newValue, data, setFieldValue, exception, isCard) {
    if (isCard) {
      newValue = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), newValue.split(':')[0], newValue.split(':')[1]);
    }

    let blockHour = false;

    const days = [
      profissionalValue.domingo,
      profissionalValue.segunda,
      profissionalValue.terca,
      profissionalValue.quarta,
      profissionalValue.quinta,
      profissionalValue.sexta,
      profissionalValue.sabado,
    ];

    const hourFormated = format(newValue, "HH:mm");

    const hourInterval = indisponibleOfCurrentProfessional.map((d) => {
      return {
        dataInitial: new Date(new Date(d.dataInit).getFullYear(), new Date(d.dataInit).getMonth(), new Date(d.dataInit).getDate(), 0, 0),
        dataFinal: new Date(new Date(d.dataFim).getFullYear(), new Date(d.dataFim).getMonth(), new Date(d.dataFim).getDate(), 0, 0),
        hourInitial: d.horaInit,
        hourFinal: d.horaFim,
      };
    });

    for (const x of hourInterval) {
      const teste =
        format(new Date(data), "dd/MM/yyyy") === format(new Date(x.dataInitial), "dd/MM/yyyy") &&
        format(new Date(data), "dd/MM/yyyy") === format(new Date(x.dataFinal), "dd/MM/yyyy") &&
        hourFormated >= x.hourInitial.padStart(5, 0) &&
        hourFormated <= x.hourFinal.padStart(5, 0);

      if (teste === true) {
        blockHour = true;
      }
    }

    const extraFiltered = extraHour.filter((a) =>
      a.professionals.some((b) => b.id === profissionalValue.id)
    );
    const verifyExtra = extraFiltered.find(
      (a) => new Date(a.dataInit).valueOf() === new Date(data).valueOf()
    );

    if (!!verifyExtra) {
      if (
        (hourFormated >= verifyExtra.horaInit.padStart(5, "0") &&
          hourFormated <= verifyExtra.horaFim.padStart(5, "0") &&
          (hourFormated <= almocoInit.padStart(5, "0") ||
            hourFormated >= almocoFim.padStart(5, "0"))) ||
        !!exception === true
      ) {
        setHour(newValue);
        const horaIni = `${newValue.getHours()}:${newValue.getMinutes() <= 9
          ? "0" + newValue.getMinutes()
          : newValue.getMinutes()
          }`;
        setFieldValue("horaInicio", horaIni);
        const horaFim = add(
          new Date().setHours(horaIni.split(":")[0], horaIni.split(":")[1], 0),
          { minutes: procedimentoValue.duracao }
        );
        setFieldValue(
          "horaFim",
          `${horaFim.getHours()}:${horaFim.getMinutes() <= 9
            ? "0" + horaFim.getMinutes()
            : horaFim.getMinutes()
          }`
        );
      }
    }
    else {
      const tempFormated = new Date('10/10/2000 ' + hourFormated).valueOf();
      const tempIni = new Date('10/10/2000 ' + days[data.getDay()]?.horaInit).valueOf();
      const tempFim = new Date('10/10/2000 ' + days[data.getDay()]?.horaFim).valueOf();
      const tempAlIni = new Date(almocoInit);
      const tempAlFim = new Date(almocoFim);
      if ((((tempIni <= tempFormated) && (tempFim >= tempFormated)) && ((tempFormated <= tempAlIni) || (tempFormated >= tempAlFim))) || !!exception === true) {

        setHour(newValue);
        const horaIni = `${newValue.getHours()}:${newValue.getMinutes() <= 9 ? '0' + newValue.getMinutes() : newValue.getMinutes()}`
        setFieldValue("horaInicio", horaIni)
        const tempHoraFim = add(new Date().setHours(horaIni.split(':')[0], horaIni.split(':')[1], 0), { minutes: procedimentoValue.duracao })
        const horaFim = `${tempHoraFim.getHours()}:${tempHoraFim.getMinutes() <= 9 ? '0' + tempHoraFim.getMinutes() : tempHoraFim.getMinutes()}`
        setFieldValue("horaFim", horaFim)
      }
    }

    return days[newValue];
  }


  const onDateFocus = useCallback(() => {
    const temp1 = indisponible
      .map((p) => p.professionals.map((u) => u.nome === profissionalValue.nome))
      .flat(1);
    if (profList !== temp1) {
      setProfList(temp1);
    }

    const temp2 = profList.some((i) => i === true);
    if (verifyProf !== temp2) {
      setVerifyProf(temp2);
    }
  }, [indisponible, profList, profissionalValue.nome, verifyProf]);

  const onDateChange = (newValue, exception, setFieldValue) => {
    let blockDate = false;
    const rightData = new Date(
      newValue.getFullYear(),
      newValue.getMonth(),
      newValue.getDate()
    );
    const extraFiltered = extraHour.filter((a) =>
      a.professionals.some((b) => b.id === profissionalValue.id)
    );
    const verifyExtra = extraFiltered.find(
      (a) => new Date(a.dataInit).valueOf() === new Date(rightData).valueOf()
    );
    setIsExtraDay(verifyExtra);
    for (const x of indisponibleOfCurrentProfessional) {
      const teste =
        rightData < add(new Date(x.dataInit), { hours: -15, minutes: -2 }) ||
        rightData > add(new Date(x.dataFim), { hours: -15, minutes: -2 });
      if (!teste) {
        blockDate = true;
      }
    }
    if (date !== rightData) {
      setDate(rightData);
      setFieldValue("data", rightData);
    }
  }
  const hoursToSchedule = (rightData, scheduleTemp) => {
    const filteredProc = procedimentos.filter(l => l.id === procedimentoValue.id)[0];
    setProcTime(filteredProc)
    const weekDays = [profissionalValue.domingo, profissionalValue.segunda, profissionalValue.terca, profissionalValue.quarta, profissionalValue.quinta, profissionalValue.sexta, profissionalValue.sabado]
    let day;
    let hours
    let dayExtra = isExtraDay;


    let dayWeek = weekDays[new Date(rightData).getDay(rightData)];

    if (!!(weekDays[new Date(rightData).getDay(rightData)]) && (!!isExtraDay)) {
      let scheduleHoursDayExtra
      let scheduleHoursDayWeek
      if (!!dayWeek) {
        //separar hora de almoço e sem hora de almoço. nos dias normais
        if (dayWeek.horaInit < almocoInit && almocoFim < dayWeek.horaFim) {
          //hora de almoço no meio do horario normal
          //antes do almoço
          let init1 = new Date(2000, 10, 10, dayWeek.horaInit.split(':')[0], dayWeek.horaInit.split(':')[1]);
          let initLunch = new Date(2000, 10, 10, almocoInit.split(':')[0], almocoInit.split(':')[1])
          const beforeLunch = [init1];
          for (let i = 0; init1 <= add(initLunch, { minutes: -filteredProc.duracao }); i++) {
            beforeLunch.push(add(init1, { minutes: filteredProc.duracao }))
            init1 = add(init1, { minutes: filteredProc.duracao })
          }
          //depois do almoço
          let init2 = new Date(2000, 10, 10, almocoFim.split(':')[0], almocoFim.split(':')[1]);
          const fim = new Date(2000, 10, 10, dayWeek.horaFim.split(':')[0], dayWeek.horaFim.split(':')[1]);
          let laterLunch = [init2];
          for (let i = 0; init2 <= add(fim, { minutes: -filteredProc.duracao }); i++) {
            laterLunch.push(add(init2, { minutes: filteredProc.duracao }))
            init2 = add(init2, { minutes: filteredProc.duracao })
          }
          if ((filteredProc.duracao > 40 && filteredProc.duracao < 60) ||
            (filteredProc.duracao > 60)) {
            beforeLunch.pop()
          }
          laterLunch.pop()
          scheduleHoursDayWeek = beforeLunch.concat(laterLunch);
        } else {
          //hora de almoço fora do horario normal de trabalho independente de hora ou dentro.
          let init1 = new Date(2000, 10, 10, dayWeek.horaInit.split(':')[0], dayWeek.horaInit.split(':')[1]);
          let initLunch = new Date(2000, 10, 10, dayWeek.horaFim.split(':')[0], dayWeek.horaFim.split(':')[1])
          const beforeLunch = [init1];
          for (let i = 0; init1 <= add(initLunch, { minutes: -filteredProc.duracao }); i++) {
            beforeLunch.push(add(init1, { minutes: filteredProc.duracao }))
            init1 = add(init1, { minutes: filteredProc.duracao })
          }
          scheduleHoursDayWeek = beforeLunch
        }
      }
      if (!!dayExtra) {
        let init1 = new Date(2000, 10, 10, dayExtra.horaInit.split(':')[0], dayExtra.horaInit.split(':')[1]);
        let fim = new Date(2000, 10, 10, dayExtra.horaFim.split(':')[0], dayExtra.horaFim.split(':')[1])
        const total = [init1];
        for (let i = 0; init1 <= add(fim, { minutes: -filteredProc.duracao }); i++) {
          total.push(add(init1, { minutes: filteredProc.duracao }))
          init1 = add(init1, { minutes: filteredProc.duracao })
        }

        scheduleHoursDayExtra = total;
      }

      if (scheduleHoursDayExtra > scheduleHoursDayWeek) {
        hours = scheduleHoursDayWeek.concat(scheduleHoursDayExtra)
      } else {
        scheduleHoursDayExtra.pop()
        hours = scheduleHoursDayExtra.concat(scheduleHoursDayWeek)
      }
      //dia da semana completo com hora de almoço no meio de expediente resolvido.
      //agora sem horario de almoço no expediente.
    } else if (!!(weekDays[new Date(rightData).getDay(rightData)]) && (!!!isExtraDay)) {
      if (dayWeek.horaInit < almocoInit && almocoFim < dayWeek.horaFim) {
        //hora de almoço no meio do horario normal
        //antes do almoço
        let init1 = new Date(2000, 10, 10, dayWeek.horaInit.split(':')[0], dayWeek.horaInit.split(':')[1]);
        let initLunch = new Date(2000, 10, 10, almocoInit.split(':')[0], almocoInit.split(':')[1])
        const beforeLunch = [init1];
        for (let i = 0; init1 <= add(initLunch, { minutes: -filteredProc.duracao }); i++) {
          beforeLunch.push(add(init1, { minutes: filteredProc.duracao }))
          init1 = add(init1, { minutes: filteredProc.duracao })
        }
        //depois do almoço
        let init2 = new Date(2000, 10, 10, almocoFim.split(':')[0], almocoFim.split(':')[1]);
        const fim = new Date(2000, 10, 10, dayWeek.horaFim.split(':')[0], dayWeek.horaFim.split(':')[1]);
        let laterLunch = [init2];
        for (let i = 0; init2 <= add(fim, { minutes: -filteredProc.duracao }); i++) {
          laterLunch.push(add(init2, { minutes: filteredProc.duracao }))
          init2 = add(init2, { minutes: filteredProc.duracao })
        }
        if ((filteredProc.duracao > 40 && filteredProc.duracao < 60) ||
          (filteredProc.duracao > 60)) {
          beforeLunch.pop()
        }
        hours = beforeLunch.concat(laterLunch);
      } else {
        //hora de almoço fora do horario normal de trabalho independente de hora ou dentro.
        let init1 = new Date(2000, 10, 10, dayWeek.horaInit.split(':')[0], dayWeek.horaInit.split(':')[1]);
        let initLunch = new Date(2000, 10, 10, dayWeek.horaFim.split(':')[0], dayWeek.horaFim.split(':')[1])
        const beforeLunch = [init1];
        for (let i = 0; init1 <= add(initLunch, { minutes: -filteredProc.duracao }); i++) {
          beforeLunch.push(add(init1, { minutes: filteredProc.duracao }))
          init1 = add(init1, { minutes: filteredProc.duracao })
        }
        hours = beforeLunch

      }

    } else if (!!!(weekDays[new Date(rightData).getDay(rightData)]) && (!!isExtraDay)) {
      //APENAS HORARIO EXTRA ABAIXO COM HORARIO DE ALMOÇO NO MEIO.

      //ABAIXO HORA EXTRA COM HORARIO DE ALMOÇO NO MEIO
      day = isExtraDay
      if (day.horaInit < almocoInit && almocoFim < day.horaFim) {
        //antes do almoço
        let init1 = new Date(2000, 10, 10, day.horaInit.split(':')[0], day.horaInit.split(':')[1]);
        let initLunch = new Date(2000, 10, 10, almocoInit.split(':')[0], almocoInit.split(':')[1])
        const beforeLunch = [init1];
        for (let i = 0; init1 <= add(initLunch, { minutes: -filteredProc.duracao }); i++) {
          beforeLunch.push(add(init1, { minutes: filteredProc.duracao }))
          init1 = add(init1, { minutes: filteredProc.duracao })
        }

        let init2 = new Date(2000, 10, 10, almocoFim.split(':')[0], almocoFim.split(':')[1]);
        const fim = new Date(2000, 10, 10, day.horaFim.split(':')[0], day.horaFim.split(':')[1])
        let laterLunch = [init2];
        for (let i = 0; init2 <= add(fim, { minutes: -filteredProc.duracao }); i++) {
          laterLunch.push(add(init2, { minutes: filteredProc.duracao }))
          init2 = add(init2, { minutes: filteredProc.duracao })
        }
        if ((filteredProc.duracao > 40 && filteredProc.duracao < 60) ||
          (filteredProc.duracao > 60)) {
          beforeLunch.pop()
        }
        hours = beforeLunch.concat(laterLunch);
      } else {
        let init1 = new Date(2000, 10, 10, day.horaInit.split(':')[0], day.horaInit.split(':')[1]);
        let initLunch = new Date(2000, 10, 10, day.horaFim.split(':')[0], day.horaFim.split(':')[1])
        const beforeLunch = [init1];
        for (let i = 0; init1 <= add(initLunch, { minutes: -filteredProc.duracao }); i++) {
          beforeLunch.push(add(init1, { minutes: filteredProc.duracao }))
          init1 = add(init1, { minutes: filteredProc.duracao })
        }
        hours = beforeLunch
      }
    }

    if (!!hours) {
      const filteredHours = [];
      for (const x of hours) {
        const xHour = x.getHours() <= 9 ? '0' + JSON.stringify(x.getHours()) : JSON.stringify(x.getHours())
        const xMin = x.getMinutes() == 0 ? JSON.stringify(x.getMinutes()) + '0' : JSON.stringify(x.getMinutes())
        const formated = `${xHour + ":" + xMin}`
        const hasSchedule = scheduleTemp.find((a) => a.horaInicio <= formated && formated < a.horaFim)
        filteredHours.push(!!hasSchedule ? { keyId: JSON.stringify(new Date().getTime() + Math.floor(Math.random() * 65536)), picked: true, hour: formated, ...hasSchedule } : { keyId: JSON.stringify(new Date().getTime() + Math.floor(Math.random() * 65536)), picked: false, selected: "", hour: formated, ...hasSchedule });
      }
      return filteredHours;
    }
  }

  function statusName(status) {
    switch (status) {
      case 'confirmed':
        return 'Confirmado'
      case 'await':
        return 'Aguardando'
      case 'not_appear':
        return 'Não Comp.'
      case 'finished':
        return 'Finalizado'
      case 'reject':
        return 'Cancelado'
      default:
        break;
    }
  }

  const handleClickOpenDialogFinish = (id) => {
    setOpenDialogFinish(id);
  };
  const handleCloseDialogiFinish = () => {
    setOpenDialogFinish(false);
  }
  const handleCloseDialogReject = () => {
    setOpenDialogReject(false);
  }
  const handleChangeTextRejectReason = (event) => {
    setTextRejectReason(event.target.value)
  }

  const showRefuseModal = () => {
    setOpenDialogReject(true)
  }
  const showFinishModal = () => {
    setOpenDialogFinish(true)
  }

  async function handleReject(id) {
    try {
      if (textRejectReason.length < 1) {
        setError('Campo de mensagem vazio')
        setSeverity('error')
        return
      }
      const result = await rejectSchedule(id, textRejectReason, userData)
      setError('Agendamento rejeitado')
      setSeverity('success')
      setOpenDialogReject(false)
      props.setState(0)


    } catch (error) {

      setError('Error ao rejeitar agendamento')
      setSeverity('error')
      setOpenDialogReject(false)
    }
  }

  async function handleAccept(id) {
    try {
      const result = await confirmSchedule(id, userData)

      setError('Agendamento confirmado')
      setSeverity('success')
      props.setState(0)

    } catch (error) {
      setError('Error ao aceitar agendamento')
      setSeverity('error')
    }
  }

  async function handleAppear(id, beenPresent) {
    try {
      const result = await finishSchedule(id, { beenPresent, userData })

      setError('Agendamento finalizado')
      setSeverity('success')
      setOpenDialogFinish(true)
      props.setState(0)

    } catch (error) {
      setError('Error ao finalizar agendamento')
      setSeverity('error')
      setOpenDialogFinish(true)
    }
  }

  async function handleNotAppear(id, beenPresent) {
    try {
      const result = await finishSchedule(id, { beenPresent, userData })

      setError('Agendamento finalizado')
      setSeverity('success')
      setOpenDialogFinish(true)
      props.setState(0)

    } catch (error) {
      setError('Error ao finalizar agendamento')
      setSeverity('error')
      setOpenDialogFinish(true)
    }
  }

  useEffect(async () => {
    const locais = await getAllUnidadesToSchedule();
    setLocais(locais);
    const unity = await schedule.profissional.unidade;
    const professional = await schedule.profissional;
    const procedure = await schedule.procedimento;
    const servico = await schedule.servico;
    const depServ = await schedule.servico.disponivelDependente;
    const depProc = await schedule.procedimento.disponivelDependente;

    // const tempLoc = servicos.map((option) => option)

    const tempLoc = locais.filter((unidade) =>
      unidade.professionals.some(
        (p) => p.idServico === servico.id
      )
    )[0];


    const profissionais = tempLoc.professionals.filter(
      (prof) => prof.idServico === servico.id
    );

    const procedimento = profissionais.filter(
      (l) => l.id === professional.id
    )[0].procedimentos;

    setProcedimentos(procedimento);
    setProcedimentoForDependent(depProc)
    setServicoForDependent(depServ)
    setServicoValue(servico)
    setUnidadeValue(unity)
    setProfissionalValue(professional)
    setProcedimentoValue(procedure)
  }, [])

  useEffect(() => {
    if (!profissionalValue || !profissionalValue.id || !pickedDate) {
      setProfessionalSchedules([])
      return;
    }
    const handleProfChange = async () => {
      try {
        setLoadingProf(true)
        const profSchedules = await getAllSchedules({
          professional: profissionalValue.id,
          date: format(new Date(pickedDate), 'yyyy-MM-dd')
        })
        setProfessionalSchedules(profSchedules || [])
      } catch (error) {
        setProfessionalSchedules([])
      } finally {
        setLoadingProf(false)
      }
    }
    handleProfChange();
  }, [profissionalValue, pickedDate])

  useEffect(() => {
    if(lockExtraHourInit || !extraHour || extraHour.length === 0) return;
    onDateChange(date, '', (...params) => {})    
    setLockExtraHourInit(true);
  }, [extraHour])

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"

    >
      <Collapse in={error} style={{
        position: 'absolute',
        bottom: 20,
        left: 20,
        zIndex: 99999
      }}>
        <Alert
          severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setError(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {error}
        </Alert>
      </Collapse>
      <Container maxWidth="md" disableGutters>
        <Formik
          initialValues={{
            idUser: schedule.idUser,
            idServico: schedule.idServico,
            idUnidade: schedule.idUnidade,
            idProfissional: schedule.idProfissional,
            idProcedimento: schedule.idProcedimento,
            situacao: schedule.situacao,
            oab: schedule.oab,
            cpf: schedule.cpf,
            status: schedule.status,
            data: new Date(schedule.data + 'T04:00:00'),
            horaInicio: schedule.horaInicio,
            horaFim: schedule.horaFim,
            observacao: schedule.observacao,
            exception: schedule.exception,
          }}
          onSubmit={async (values) => {
            const { data, horaFim, horaInicio } = values;

            for (const unavailable of indisponibleOfCurrentProfessional) {
              const startHour = unavailable.horaInit.length === 4 ? '0' + unavailable.horaInit : unavailable.horaInit;
              const endHour = unavailable.horaFim.length === 4 ? '0' + unavailable.horaFim : unavailable.horaFim;
              const startDate = new Date(unavailable.dataInit);
              const endDate = new Date(unavailable.dataFim);
              const horaInit = horaInicio.length === 4 ? '0' + horaInicio : horaInicio;
              const horaF = horaFim.length === 4 ? '0' + horaFim : horaFim;

              if (data >= startDate && data <= endDate) {
                if (startHour <= horaInit && horaInit <= endHour) {
                  setHorarioOcupado(true);
                  return;
                }
              }
            }
            if (professionalHours.some((a) => a.hour == values.horaInicio.padStart(5, '0'))) {
              setInvalidHour(false);
              try {
                setOpen(true)
                const temp = {
                  ...values,
                  horaInicio: values.horaInicio.length < 5 ? '0' + values.horaInicio : values.horaInicio,
                  horaFim: values.horaFim.length < 5 ? '0' + values.horaFim : values.horaFim,
                }
                const result = await updateScheduleEdit({
                  ...values,
                  id: schedule.id,
                  horaInicio: values.horaInicio.length < 5 ? '0' + values.horaInicio : values.horaInicio,
                  horaFim: values.horaFim.length < 5 ? '0' + values.horaFim : values.horaFim, user
                })
                await alterationLog({ action: 'CREATE', idUser: user.id, before: '', after: temp, menu: "Agendamentos", subMenu: "Agendamentos", idUserChanged: schedule.idUser, });
                props.setState(0)
              } catch (error) {
                if (error.response.data.error === 'HOUR_UNAVALIABLE') {
                  setHorarioOcupado(true)
                }
                console.log('ERROR_SCHEDULE_NEW');
                console.log(error);
                setOpen(false);
              }
            } else if (exception === true) {
              setOpen(true)
              const result = await updateScheduleEdit({
                userData,
                ...values,
                id: schedule.id,
                horaInicio: values.horaInicio.length < 5 ? '0' + values.horaInicio : values.horaInicio,
                horaFim: values.horaFim.length < 5 ? '0' + values.horaFim : values.horaFim,
              })
              await alterationLog({ action: 'CREATE', idUser: user.id, before: '', after: values, menu: "Agendamentos", subMenu: "Agendamentos", });
              props.setState(0)
            } else {
              setInvalidHour(true);
            }
          }}
          validationSchema={Yup.object().shape({
            idUser: Yup.string()
              .required('Campo obrigatório'),
            idProfissional: Yup.string()
              .required('Campo obrigatório'),
            idServico: Yup.string()
              .required('Campo obrigatório'),
            idProcedimento: Yup.string()
              .required('Campo obrigatório'),
            horaInicio: Yup.string()
              .required('Campo obrigatório'),
            horaFim: Yup.string()
              .required('Campo obrigatório'),
            data: Yup.date()
              .required('Campo obrigatório'),
          })}
          validator={() => ({})}

        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values
          }) => (
            <Card style={{ backgroundColor: 'white', paddingTop: 20, marginTop: 20, marginBottom: 20 }}>
              <CardContent>
                <Grid container justify={'center'} spacing={2}>
                  <>
                    <Grid md={12}>
                      <Typography variant={'h1'} style={{ marginBottom: 30 }}>
                        Editar agendamento
                      </Typography>
                    </Grid>
                    <Grid md={12}>
                      <Typography variant={'h4'} >
                        Dados do Associado
                      </Typography>
                      <Divider style={{ marginBottom: 50 }} />
                    </Grid>

                    <Grid container spacing={3} style={{ marginBottom: 30, marginLeft: 0 }}>
                      <Grid item md={6} >
                        <Grid item xs={12} style={{}}>
                          <Avatar
                            className={classes.Avatar}
                            src={schedule.user.img ? URL.createObjectURL(schedule.user.img) : schedule.user.imgProfile}
                          />
                        </Grid>
                      </Grid>
                      <Grid container md={6} spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Tipo"
                            name="tipo"
                            id='tipo'
                            value={capitalize(schedule.user.userType)}
                            variant="outlined"
                            InputProps={{
                              readOnly: true
                            }}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Situação"
                            name="situacao"
                            id='situacao'
                            value={capitalize(schedule.user.situacao)}
                            variant="outlined"
                            InputProps={{
                              readOnly: true
                            }}
                          />
                          {schedule.situacao == 'ATIVO' && (
                            // <Grid item md={8} xs={12}>
                            <Alert
                              severity={'warning'}
                              style={{ marginTop: 5 }}
                            >
                              Usuário Inativo
                            </Alert>
                            // </Grid>
                          )}
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="OAB"
                            name="oab"
                            id='oab'
                            value={schedule.user.oab}
                            variant="outlined"
                            InputProps={{
                              readOnly: true
                            }}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="CPF"
                            name="cpf"
                            id='cpf'
                            value={schedule.user.cpf}
                            variant="outlined"
                            InputProps={{
                              readOnly: true
                            }}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            label="Nome"
                            name="nome"
                            id='nome'
                            value={schedule.user.nome}
                            variant="outlined"
                            InputProps={{
                              readOnly: true
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid md={12}>
                      <Typography variant={'h4'} >
                        Dados do Agendamento
                      </Typography>
                      <Divider style={{ marginBottom: 50 }} />
                    </Grid>

                    <Grid container spacing={3} style={{ marginBottom: 37, marginLeft: 0 }}>
                      <Grid container md={12} spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Status"
                            name="status"
                            id='status'
                            onBlur={handleBlur}
                            value={values.status}
                            onChange={(e, value) => {
                              setFieldValue('status', e.target.value)
                            }}
                            variant="outlined"
                            select
                            InputProps={{
                              readOnly: true
                            }}
                          >
                            {status.map((option) => (
                              <MenuItem
                                style={{
                                  backgroundColor: color.white,
                                }}
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12} >
                          <Autocomplete
                            fullWidth
                            id="select-servico"
                            color={touched.idServico && errors.idServico ? 'error' : 'primary'}
                            noOptionsText={'Nenhum serviço encontrado'}
                            options={servicos.map((option) => option)}
                            defaultValue={schedule.servico}
                            onChange={async (e, value) => {

                              if (value && value.id) {

                                setIndisponibleOfCurrentProfessional([]);
                                setFieldValue("idServico", value ? value.id : "");
                                setServicoValue(value ? value : "");
                                setServicoForDependent(value.disponivelDependente);
                                setUnidadeValue({});
                                setProfissionalValue({});
                                setStateShow(false)
                                setProcedimentoValue({});
                                setLocaisFiltered(
                                  locais.filter((unidade) =>
                                    unidade.professionals.some(
                                      (p) => p.idServico === value.id
                                    )
                                  )
                                );
                              } else {
                                setFieldValue('idServico', '')
                                setUnidadeValue({});
                                setProfissionalValue({});
                                setProcedimentoValue({});
                              }
                            }}
                            getOptionLabel={option => option.nome}
                            getOptionSelected={(x, u) => {
                              return x.nome === u.nome
                            }}
                            onBlur={handleBlur}
                            classes={{
                              popper: classes.option
                            }}
                            renderInput={(params) => (
                              <TextField {...params}
                                onBlur={handleBlur}
                                label="Serviço"
                                variant="outlined"
                                error={Boolean(touched.idServico && errors.idServico)}
                                helperText={touched.idServico && errors.idServico}
                              />
                            )}
                          // disabled={values.status !== 'await' || values.status !== 'confirmed'}
                          />
                          {!servicoForDependent && values.idServico != "" && schedule.user.userType != 'TITULAR' && (
                            <Alert
                              severity={'warning'}
                              style={{ marginTop: 5 }}
                            >
                              Serviço disponível somente para titular
                            </Alert>
                          )}
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Autocomplete
                            fullWidth
                            id="select-unidade"
                            color={touched.idUnidade && errors.idUnidade ? 'error' : 'primary'}
                            value={unidadeValue}
                            options={locaisFiltered ? locaisFiltered.map((option) => option) : []}
                            defaultValue={schedule.profissional.unidade}
                            onChange={(e, value) => {

                              setIndisponibleOfCurrentProfessional([]);
                              if (value && value.id) {
                                setFieldValue("idUnidade", value.id);
                                setUnidadeValue(value);
                                setProfissionalValue({});
                                setProcedimentoValue({});
                                const profissionais = value.professionals.filter(
                                  (prof) => prof.idServico === servicoValue.id
                                );
                                setProfissionais(profissionais);
                              } else {
                                setFieldValue("idUnidade", "");
                                setUnidadeValue({});
                                setProfissionalValue({});
                                setProcedimentoValue({});
                              }
                            }}
                            getOptionLabel={option => option.nome}
                            getOptionSelected={(x, u) => {
                              return x.nome === u.nome
                            }}
                            onBlur={handleBlur}
                            classes={{
                              popper: classes.option
                            }}
                            renderInput={(params) => (
                              <TextField {...params}
                                onBlur={handleBlur}
                                label="Unidade"
                                error={Boolean(touched.idUnidade && errors.idUnidade)}
                                helperText={touched.idUnidade && errors.idUnidade}
                                variant="outlined" />
                            )}
                          // disabled={values.status !== 'await' || values.status !== 'confirmed'}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Autocomplete
                            fullWidth
                            id="select-profissional"
                            color={touched.idProfissional && errors.idProfissional ? 'error' : 'primary'}
                            value={profissionalValue}
                            options={profissionais ? profissionais.map((option) => option) : []}
                            defaultValue={schedule.profissional}
                            onChange={(e, value) => {

                              if (value && value.id) {
                                setFieldValue("idProfissional", value.id);
                                setProfissionalValue(value);
                                setProcedimentoValue({});
                                const procedimento = profissionais.filter(
                                  (l) => l.id === value.id
                                )[0].procedimentos;
                                const initAlmoco = profissionais.filter(
                                  (l) => l.id === value.id
                                )[0].horaAlmocoInicio;
                                const fimAlmoco = profissionais.filter(
                                  (l) => l.id === value.id
                                )[0].horaAlmocoFim;
                                const localIndis = indisponible.filter(
                                  (i) =>
                                    i.professionals.filter((p) => p.id === value.id)
                                      .length > 0
                                );
                                setAlmocoInit(initAlmoco);
                                setAlmocoFim(fimAlmoco);
                                setProcedimentos(procedimento);
                                setIndisponibleOfCurrentProfessional(localIndis);
                                setFieldValue("data", "");
                              } else {
                                setFieldValue("idProfissional", "");
                                setProfissionalValue({});
                                setProcedimentoValue({});
                                setIndisponibleOfCurrentProfessional([]);
                              }
                            }}
                            getOptionLabel={option => option.nome}
                            getOptionSelected={(x, u) => {
                              return x.nome === u.nome
                            }}
                            onBlur={handleBlur}
                            classes={{
                              popper: classes.option
                            }}
                            renderInput={(params) => (
                              <TextField {...params}
                                onBlur={handleBlur}
                                label="Profissional/Recurso"
                                error={Boolean(touched.idProfissional && errors.idProfissional)}
                                helperText={touched.idProfissional && errors.idProfissional}
                                variant="outlined" />
                            )}
                          // disabled={values.status !== 'await' || values.status !== 'confirmed'}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Autocomplete
                            fullWidth
                            id="select-procedimento"
                            color={touched.idProcedimento && errors.idProcedimento ? 'error' : 'primary'}
                            value={procedimentoValue}
                            options={procedimentos ? procedimentos.map((option) => option) : []}
                            defaultValue={schedule.procedimento}
                            onChange={(e, value) => {
                              if (value && value.id) {
                                setFieldValue("idProcedimento", value.id);
                                setProcedimentoValue(value);
                                setProcedimentoForDependent(
                                  value.disponivelDependente
                                );
                                const procedure = procedimentos.filter(
                                  (l) => l.id === value.id
                                )[0].duracao;
                                const autoConfirmation = procedimentos.filter(
                                  (l) => l.id === value.id
                                )[0].confirmation;
                                setProcedures(procedure);
                                setFieldValue(
                                  "automaticConfirmation",
                                  autoConfirmation
                                );
                              } else {
                                setFieldValue("idProcedimento", "");
                                setProcedimentoValue({});
                              }
                            }}
                            getOptionLabel={option => option.nome}
                            getOptionSelected={(x, u) => {
                              return x.nome === u.nome
                            }}
                            onBlur={handleBlur}
                            classes={{
                              popper: classes.option
                            }}
                            renderInput={(params) => (
                              <TextField {...params}
                                onBlur={handleBlur}
                                label="Procedimento"
                                error={Boolean(touched.idProcedimento && errors.idProcedimento)}
                                helperText={touched.idProcedimento && errors.idProcedimento}
                                variant="outlined" />
                            )}
                          // disabled={values.status !== 'await' || values.status !== 'confirmed'}
                          />
                          {!procedimentoForDependent && values.idProcedimento != "" && schedule.user.userType != 'TITULAR' && (
                            <Alert
                              severity={'warning'}
                              style={{ marginTop: 5 }}
                            >
                              Serviço disponível somente para titular
                            </Alert>
                          )}
                        </Grid>
                        {(stateShow) ?
                          <Grid item md={6} xs={12}>
                            <FormControlLabel
                              value="end"
                              name="exception"
                              control={
                                <Checkbox
                                  checked={values.exception}
                                  name="exception"
                                  color="primary"
                                  onChange={(event) => {
                                    handleChange(event)
                                    setException(event.target.checked)
                                  }}
                                />
                              }
                              label="Horário extra"
                              labelPlacement="end"
                            />
                          </Grid> : ""}
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{ marginBottom: 50, marginLeft: 0 }}>
                      <Grid container md={12} spacing={3}>
                        <Grid item md={6} xs={12} style={{ marginTop: -16 }}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <DatePicker
                                fullWidth
                                margin="normal"
                                id="data"
                                label="Data"
                                format="dd/MM/yyyy"
                                autoOk
                                shouldDisableDate={disableWeekDays}
                                error={!stateShow}
                                helperText={stateShow ? "" : "Selecione uma data para continuar"}
                                disabled={
                                  (values.idProcedimento !== "" ? false : true)
                                }
                                value={date}
                                onFocus={() => onDateFocus()}
                                onChange={(newValue) => {
                                  setStateShow(true)
                                  onDateChange(newValue, values.exception, setFieldValue);
                                }}
                                inputVariant={"outlined"}
                                variant="inline"
                                minDate={new Date()}
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>
                        {values.exception && (
                          <Grid item md={6} xs={12} style={{ marginTop: -16 }}>
                            <MuiThemeProvider theme={defaultMaterialTheme}>
                              <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={pt}
                              >
                                <TimePicker
                                  fullWidth
                                  id="time-picker-init"
                                  label="Hora"
                                  margin={"normal"}
                                  autoOk
                                  minDateMessage
                                  minutesStep={5}
                                  hideTabs
                                  ampm={false}
                                  disabled={
                                    (values.data !== "" ? false : true)
                                    // || values.status !== 'await'
                                    // || values.status !== 'confirmed'
                                  }
                                  error={Boolean(
                                    touched.horaInicio && errors.horaInicio
                                  )}
                                  helperText={touched.horaInicio && errors.horaInicio}
                                  value={hour}
                                  onChange={(newValue) => {
                                    pickHour(
                                      newValue,
                                      values.data,
                                      setFieldValue,
                                      values.exception,
                                      false
                                    );
                                  }}
                                  variant="inline"
                                  inputVariant={"outlined"}
                                />
                              </MuiPickersUtilsProvider>
                            </MuiThemeProvider>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    {!_.isEmpty(procedimentoValue) && (values.status === 'await' || values.status === 'confirmed') ? (
                      (stateShow === true) ? (
                        <Grid
                          container
                          md={12}
                          spacing={3}
                          style={{ marginBottom: 40 }}
                        >
                          <Grid item md={6}>
                            <Grid item md={12}>
                              <Typography variant={"h4"}>
                                Quadro de horários
                              </Typography>
                              <Divider style={{ marginBottom: 30 }} />
                            </Grid>
                            <Grid container md={12}>
                              {/* aqui mostra o quadro de horas */}
                              {
                                professionalHours.length > 0 ? (professionalHours.map((p, i) => {
                                  const { data } = values;
                                  const { hour } = p;

                                  if (professionalHours.at(-1).keyId === p.keyId) {
                                    return null;
                                  }

                                  if (hour >= almocoInit && hour < almocoFim) {
                                    return null;
                                  }
                                  // aqui termina o que mostra os quadros de horas, retirando o horario de almoço. não faz divisão com o horario procedimento
                                  for (const unavailable of indisponibleOfCurrentProfessional) {
                                    const startHour = unavailable.horaInit.length === 4 ? '0' + unavailable.horaInit : unavailable.horaInit;
                                    const endHour = unavailable.horaFim === 4 ? '0' + unavailable.horaFim : unavailable.horaFim;
                                    const startDate = new Date(unavailable.dataInit);
                                    const endDate = new Date(unavailable.dataFim);
                                    const procTimesegundos = parseFloat(procTime.duracao) * 60
                                    const horaagendamento = parseFloat(hour.replace(/:/i, ".")) * 3600
                                    const horaInicialAgendamentoFim = procTimesegundos + horaagendamento;
                                    const indisponibilidadeemsegundosinicial = parseFloat(startHour.replace(/:/i, ".")) * 3600
                                    const indisponiblidadeemsegundosfim = parseFloat(endHour.replace(/:/i, ".")) * 3600
                                    if (data >= startDate && data <= endDate) {
                                      if (
                                        (indisponibilidadeemsegundosinicial <= horaagendamento && horaagendamento <= indisponiblidadeemsegundosfim) || (horaagendamento <= indisponibilidadeemsegundosinicial && indisponibilidadeemsegundosinicial < horaInicialAgendamentoFim)
                                      ) {
                                        p.picked = true;
                                      }
                                    }
                                  }

                                  return (
                                    <Grid item md={3} key={p.keyId}
                                      className={classes.cardHours}
                                      data-tip
                                      data-for={p.keyId}
                                      onClick={() => {
                                        if (!p.picked) {
                                          pickHour(
                                            p.hour,
                                            values.data,
                                            setFieldValue,
                                            values.exception,
                                            true
                                          );
                                          p.selected = p.keyId;
                                          setCard(p.keyId);
                                        }
                                      }}
                                    >
                                      <a
                                        data-tip
                                        data-for={p.keyId}
                                        style={{
                                          backgroundColor: card == p.selected ? '#A93533' : p.picked ? '#313131' : '#E5E5E5',
                                          width: '90%',
                                          padding: '12px 0px',
                                          borderRadius: 12,
                                          color: p.picked || card == p.selected ? 'white' : 'black',
                                        }}
                                      >{p.hour}</a>
                                      <ReactTooltip
                                        id={p.keyId}
                                        effect='solid'
                                        backgroundColor='#313131'
                                      >
                                        <Grid>
                                          {!!p.user && (
                                            <Avatar
                                              className={classes.Avatar}
                                              src={!!p.user ? p.user.img ? URL.createObjectURL(p.user.img) : p.user.imgProfile : ''}
                                              style={{
                                                width: 50,
                                                height: 50,
                                              }}
                                            />
                                          )}
                                          <Typography
                                            variant={'p'}
                                            style={{
                                              color: 'white'
                                            }}
                                          >
                                            {`${!!p.user ? p.user.nome : p.picked ? 'Horário Indisponível' : 'Horário Disponivel'}`}
                                          </Typography>
                                        </Grid>
                                      </ReactTooltip>
                                    </Grid>
                                  );
                                })) : null
                              }
                            </Grid>
                          </Grid>

                          <Grid item md={6}>
                            <Grid item md={12}>
                              <Typography variant={"h4"}>
                                {'Horários extras (personalizados)'}
                              </Typography>
                              <Divider style={{ marginBottom: 30 }} />
                            </Grid>
                            <Grid container md={12}>
                              {professionalSchedulesException.length > 0
                                ? professionalSchedulesException.map((p, i) => (
                                  <Grid
                                    item
                                    md={3}
                                    key={`${p.user.id}_${p.horaInicio}`}
                                    className={classes.cardHours}
                                    data-tip
                                    data-for={`${p.user.id}_${p.horaInicio}`}
                                  >
                                    <a
                                      data-tip
                                      data-for={`${p.user.id}_${p.horaInicio}`}
                                      style={{
                                        backgroundColor: "#313131",
                                        width: "90%",
                                        padding: "12px 0px",
                                        borderRadius: 12,
                                        color: 'white'
                                      }}
                                    >
                                      {p.horaInicio}
                                    </a>
                                    <ReactTooltip
                                      id={`${p.user.id}_${p.horaInicio}`}
                                      effect="solid"
                                      backgroundColor="#313131"
                                    >
                                      <Grid>
                                        <Avatar
                                          className={classes.Avatar}
                                          src={
                                            p.user.img
                                              ? URL.createObjectURL(p.user.img)
                                              : p.user.imgProfile
                                          }
                                          style={{
                                            width: 50,
                                            height: 50,
                                          }}
                                        />
                                        <Typography
                                          variant={"p"}
                                          style={{
                                            color: "white",
                                          }}
                                        >
                                          {p.user.nome}
                                        </Typography>
                                      </Grid>
                                    </ReactTooltip>
                                  </Grid>
                                ))
                                : null}
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : null
                    ) : null}

                    <Grid container spacing={3}>
                      <Grid item md={12}>
                        <Typography variant={"h4"}>
                          Informações adicionais
                        </Typography>
                        <Divider />
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <TextField
                          multiline
                          fullWidth
                          error={Boolean(touched.observacao && errors.observacao)}
                          helperText={touched.observacao && errors.observacao}
                          label="Observações"
                          name="observacao"
                          id="observacao"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.observacao}
                          variant="outlined"
                          style={{ marginBottom: 20 }}
                          InputProps={{
                            classes: { input: classes.textArea },
                          }}
                        // disabled={values.status !== 'await' || values.status !== 'confirmed'}
                        />
                      </Grid>
                    </Grid>
                  </>
                  <Grid container spacing={1} alignItems='center' justify='space-between' className={classes.btns}>
                    {values.status === 'not_appear' || values.status === 'finished' || values.status === 'reject' ?
                      <Grid item container md={6} xs={12} spacing={3}> </Grid>
                      :
                      <Grid item container md={6} xs={12} spacing={3}>
                        <Grid item md={4} xs={6}>
                          <Button
                            fullWidth
                            color='primary'
                            disabled={isSubmitting}
                            size="large"
                            variant="contained"
                            onClick={values.status === 'await' ? () => handleAccept(schedule.id) : showFinishModal}
                          >
                            {values.status === 'confirmed' ? 'Finalizar' : 'Aceitar'}
                          </Button>
                        </Grid>
                        <Grid item md={4} xs={6}>
                          <Button
                            fullWidth
                            color='primary'
                            disabled={isSubmitting}
                            size="large"
                            variant="contained"
                            onClick={showRefuseModal}
                          >
                            {values.status === 'confirmed' ? 'Cancelar' : 'Recusar'}
                          </Button>
                        </Grid>
                      </Grid>
                    }
                    <Grid item container md={6} xs={12} spacing={3} justify='flex-end'>
                      <Grid item md={4} xs={6}>
                        <Button
                          onClick={() => {
                            props.setState(0)
                          }}
                          className={classes.submitBtn}
                          disabled={isSubmitting}
                          size="large"
                          variant="contained"
                        >
                          Voltar
                        </Button>
                      </Grid>
                      {newPermission.edit && (
                        <Grid item md={4} xs={6}>
                          <Button
                            onClick={() => handleSubmit()}
                            className={classes.submitBtn}
                            disabled={isSubmitting}
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            Atualizar
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>

              <Snackbar
                open={blockAlert}
                autoHideDuration={4000}
                onClose={handleCloseBlockAlert}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              >
                <Alert onClose={handleCloseBlockAlert} severity="error">
                  Horário bloqueado!
                </Alert>
              </Snackbar>
              <Snackbar
                open={horarioOcupado}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              >
                <AlertBar onClose={handleClose} severity="error">
                  Horário não disponível
                </AlertBar>
              </Snackbar>
              <Snackbar open={invalidHour} autoHideDuration={3000} onClose={handleCloseBlockAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                <AlertBar onClose={handleCloseBlockAlert} severity="error">
                  Horário não compatível com a agenda do Profissional! Selecione um horario compativel.
                </AlertBar>
              </Snackbar>
              <Dialog open={openDialogFinish} onClose={handleCloseDialogiFinish} aria-labelledby="form-dialog-title">
                <DialogTitle style={{ fontSize: 28 }} className={classes.diolog} id="form-dialog-title">Confirmar presença do usuário</DialogTitle>
                <DialogContent className={classes.diolog} >
                  <DialogContentText>
                    O usuário compareceu no agendamento?
                  </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.diolog} >
                  <Button onClick={() => handleNotAppear(schedule.id, false)} color="primary">
                    Não
                  </Button>
                  <Button onClick={() => handleAppear(schedule.id, true)} color="primary">
                    Sim
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog open={openDialogReject} onClose={handleCloseDialogReject} aria-labelledby="form-dialog-title">
                <DialogTitle disableTypography style={{ fontWeight: 'bold' }} className={classes.diolog} id="form-dialog-title">Cancelar agendamento</DialogTitle>
                <DialogContent className={classes.diolog} >
                  <DialogContentText>
                    Escreva o motivo do cancelamento
                  </DialogContentText>
                  <TextField
                    autoFocus
                    inputProps={{ minLength: 10 }}
                    margin="dense"
                    id="textRejectReason"
                    value={textRejectReason}
                    onChange={handleChangeTextRejectReason}
                    label="Mensagem para o usuário"
                    type="text"
                    fullWidth
                    helperText={'Minimo de 10 caracteres'}
                  />
                </DialogContent>
                <DialogActions className={classes.diolog} >
                  <Button onClick={handleCloseDialogReject} color="primary">
                    Cancelar
                  </Button>
                  <Button onClick={() => {
                    if (textRejectReason.length < 11) {
                    } else {
                      handleReject(schedule.id)
                    }
                  }}
                    color="primary">
                    Enviar
                  </Button>
                </DialogActions>
              </Dialog>
            </Card>
          )}
        </Formik>
      </Container>


      <Backdrop className={classes.backdrop} open={open}>
        <ReactLoading type={"spinningBubbles"} color={color.red} />
      </Backdrop>
    </Box>

  )
}